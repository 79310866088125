import React, { useEffect } from 'react'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Cell, Header, HeaderCell, Row } from '../common/Table'
import { ConsultationType, getConsultationTypeData, Modality } from '../../lib/modalityHelpers'
import { PricingState, fetchAddonsAction, pricingSelector } from '../../hasura/slices/pricing'
import { QueryName } from '../../hasura/queryNames'
import { SpecialistPayments_specialist_payments } from '../../hasura/graphQlQueries/types/SpecialistPayments'
import { UsersState, usersSelector } from '../../hasura/slices/users'
import { findAddon, thresholdDescription, validateThresholds } from '../../utils/addons'
import { getExtraImagePrices } from '../../lib/pricingHelpers'

import {
  SpecialistPaymentsState,
  deleteSpecialistPaymentAction,
  insertSpecialistPaymentAction,
  specialistPaymentsSelector,
  updateSpecialistPaymentAmountAction,
} from '../../hasura/slices/specialistPayments'

// @ts-ignore
import deleteIcon from '../../lib/images/delete.png'
// @ts-ignore
import closeIcon from '../../lib/images/close.png'

interface Props {
  close: () => void
  consultationType: ConsultationType
  groupId: number
}

interface EditedSpecialistPaymentAmount {
  id: number
  amount: string
}

export default function AddonPayRatesModal(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { specialistPayments }: SpecialistPaymentsState = useSelector(specialistPaymentsSelector)
  const { addons, isQuerying }: PricingState = useSelector(pricingSelector)

  const [newThreshold, setNewThreshold] = React.useState<string | undefined>()
  const [newCineloopThreshold, setNewCineloopThreshold] = React.useState<string | undefined>()
  const [newSpecialistPayment, setNewSpecialistPayment] = React.useState<string | undefined>()
  const [editedSpecialistPaymentAmount, setEditedSpecialistPaymentAmount] = React.useState<EditedSpecialistPaymentAmount | undefined>()

  const modality = getConsultationTypeData(props.consultationType)?.modality!
  const payments = getExtraImagePrices(specialistPayments, false, modality) as SpecialistPayments_specialist_payments[]
  const isCT = modality === Modality.Catscan
  const isMRI = modality === Modality.MRI
  const isUS = modality === Modality.Ultrasound
  // catscan and mri addon pricing has non-adjustable thresholds (1 region, 2 regions, and whole body)
  const canInsertAndDelete = !isCT && !isMRI

  const parsedNewThreshold = newThreshold ? parseInt(newThreshold) : undefined
  const parsedNewCineloopThreshold = newCineloopThreshold ? parseInt(newCineloopThreshold) : undefined
  const parsedNewSpecialistPayment = newSpecialistPayment ? parseFloat(newSpecialistPayment) : undefined
  const parsedEditedSpecialistPaymentAmount = editedSpecialistPaymentAmount
    ? parseFloat(editedSpecialistPaymentAmount.amount)
    : undefined
  const addon = findAddon(modality, addons, parsedNewThreshold, parsedNewCineloopThreshold)

  /*
    Effects
  */

  useEffect(() => {
    dispatch(fetchAddonsAction(accessToken))
  }, [])

  /*
    Methods
  */

  const deleteAddOnPayment = (payment: SpecialistPayments_specialist_payments) => {
    dispatch(deleteSpecialistPaymentAction(accessToken, payment.id, user!.organization.enterprise.id))
  }

  const errorMessage = (() => {
    if (newThreshold) {
      const error = validateThresholds(modality, payments, parsedNewThreshold, parsedNewCineloopThreshold)
      if (error) return error
    }

    if (newSpecialistPayment) {
      if (!isNumber(parsedNewSpecialistPayment) || parsedNewSpecialistPayment <= 0) {
        return 'Price must be greater than $0.'
      }
    }

    if (editedSpecialistPaymentAmount) {
      if (!isNumber(parsedEditedSpecialistPaymentAmount) || parsedEditedSpecialistPaymentAmount < 0) {
        return 'Price must be greater than $0.'
      }
    }
  })()

  const createSpecialistPayment = async () => {
    const enterpriseId = user!.organization.enterprise.id
    if (parsedNewSpecialistPayment) {
      const specialistPayment = {
        specialist_payment_group_id: props.groupId,
        consultation_type_id: getConsultationTypeData(props.consultationType)?.consultationTypeId,
        amount: parsedNewSpecialistPayment,
        addon_id: addon?.id,
      }
      await dispatch(insertSpecialistPaymentAction(accessToken, specialistPayment, enterpriseId))
    }

    if (isNumber(parsedEditedSpecialistPaymentAmount)) {
      await dispatch(
        updateSpecialistPaymentAmountAction(
          accessToken,
          editedSpecialistPaymentAmount!.id,
          parsedEditedSpecialistPaymentAmount,
          enterpriseId
        )
      )
    }

    setEditedSpecialistPaymentAmount(undefined)
    setNewThreshold(undefined)
    setNewCineloopThreshold(undefined)
    setNewSpecialistPayment(undefined)
  }

  return (
    <Modal fade={false} centered isOpen toggle={props.close}>
      <ModalHeader className="text-center flex-center w-100">
        Update {props.consultationType} Addon Pay Rates
        <img
          onClick={() => dispatch(props.close)}
          className="pointer position-absolute icon-m m-1"
          src={closeIcon}
          style={{ top: '10px', right: '10px' }}
        />
      </ModalHeader>

      <ModalBody className="text-center text-m p-4">
        <table style={{ tableLayout: 'fixed' }} className="text-m mx-auto">
          <tbody>
            <Row className="bold">
              <HeaderCell className="text-center">
                <Header>{isUS ? 'Image Threshold' : 'Threshold'}</Header>
              </HeaderCell>
              {isUS && (
                <HeaderCell className="text-center">
                  <Header>Cineloop Threshold</Header>
                </HeaderCell>
              )}
              <HeaderCell className="text-center">
                <Header>Amount</Header>
              </HeaderCell>
              {canInsertAndDelete && <HeaderCell />}
            </Row>

            {payments.map((p, idx) => {
              const isEditing = editedSpecialistPaymentAmount?.id === p.id
              return (
                <Row key={idx}>
                  <Cell>
                    {thresholdDescription(
                      props.consultationType,
                      p.addon?.additional_data[isUS ? 'image_threshold' : 'threshold'],
                      'image'
                    )}
                  </Cell>
                  {isUS && (
                    <Cell>
                      {thresholdDescription(props.consultationType, p.addon?.additional_data['video_threshold'], 'cineloop')}
                    </Cell>
                  )}
                  <Cell className="flex-center">
                    $
                    <Input
                      className="width-80px ml-2"
                      onChange={(e: any) => setEditedSpecialistPaymentAmount({ id: p.id, amount: e.target.value })}
                      value={isEditing ? editedSpecialistPaymentAmount?.amount : p.amount}
                      disabled={Boolean(!isEditing && editedSpecialistPaymentAmount)}
                      placeholder={isUS ? 'Image Threshold' : 'Threshold'}
                    />
                  </Cell>

                  {canInsertAndDelete && (
                    <Cell>
                      <img onClick={() => deleteAddOnPayment(p)} className="icon-xs pointer" src={deleteIcon} />
                    </Cell>
                  )}
                </Row>
              )
            })}

            {canInsertAndDelete && (
              <Row>
                <Cell>
                  <Input
                    className="width-100px mx-auto"
                    onChange={(e: any) => setNewThreshold(e.target.value)}
                    value={newThreshold || ''}
                    placeholder="Threshold"
                  />
                </Cell>
                {isUS && (
                  <Cell>
                    <Input
                      className="width-100px mx-auto"
                      onChange={(e: any) => setNewCineloopThreshold(e.target.value)}
                      value={newCineloopThreshold || ''}
                      placeholder="Cineloop Threshold"
                    />
                  </Cell>
                )}
                <Cell>
                  <div className="flex-center">
                    $
                    <Input
                      className="ml-1 width-80px"
                      onChange={(e: any) => setNewSpecialistPayment(e.target.value)}
                      value={newSpecialistPayment || ''}
                      placeholder="Price"
                    />
                  </div>
                </Cell>
                <Cell></Cell>
              </Row>
            )}
          </tbody>
        </table>

        <p
          style={{
            opacity:
              ((newSpecialistPayment && newThreshold && (newCineloopThreshold || !isUS)) || editedSpecialistPaymentAmount) &&
              errorMessage
                ? 1
                : 0,
          }}
          className="text--danger text-s bold mt-2 height-30px transition-f"
        >
          {errorMessage}
        </p>

        <Button
          onClick={createSpecialistPayment}
          disabled={
            isQuerying[QueryName.InsertSpecialistPayment] ||
            isString(errorMessage) ||
            (!editedSpecialistPaymentAmount && (!newSpecialistPayment || !newThreshold || !addon || (isUS && !newCineloopThreshold)))
          }
          color="primary"
          className="width-200px mt-2"
        >
          Save
        </Button>
      </ModalBody>
    </Modal>
  )
}
