import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import AddonPayRatesModal from './updateAddonsModal'
import SimpleStatInput from '../common/SimpleStatInput'
import { ConsultationType } from '../../lib/modalityHelpers'
import { usersSelector, UsersState } from '../../hasura/slices/users'

import {
  specialistPaymentsSelector,
  SpecialistPaymentsState,
  updateSpecialistPaymentAmountAction,
} from '../../hasura/slices/specialistPayments'

interface Props {
  groupId: number
}

export default function PayRatesAddOns(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { specialistPayments }: SpecialistPaymentsState = useSelector(specialistPaymentsSelector)

  const [addonPayRatesModalConsultationType, setAddonPayRatesModalConsultationType] = useState<ConsultationType>()

  const simpleStatPayRate = specialistPayments.find((p) => p.stat_type?.display_name === 'simple')

  return (
    <div className="mt-4">
      {addonPayRatesModalConsultationType && (
        <AddonPayRatesModal
          groupId={props.groupId}
          consultationType={addonPayRatesModalConsultationType}
          close={() => setAddonPayRatesModalConsultationType(undefined)}
        />
      )}

      <p className="bold text--gray7 text-m mb-1">Addon pay rates</p>

      <div className="d-flex mt-2 gap-80px">
        <div>
          <div>
            <p className="mb-1 semibold text-s">Update extra image pay rates for radiographs</p>
            <Button
              className="d-block width-fit-content"
              onClick={() => setAddonPayRatesModalConsultationType(ConsultationType.Xray)}
              size="sm"
            >
              Update
            </Button>
          </div>

          <div className="mt-2">
            <p className="mb-1 semibold text-s">Update extra image pay rates for ultrasound</p>
            <Button
              className="d-block width-fit-content"
              onClick={() => setAddonPayRatesModalConsultationType(ConsultationType.Ultrasound)}
              size="sm"
            >
              Update
            </Button>
          </div>

          <div className="mt-2">
            <p className="mb-1 semibold text-s">Update extra image pay rates for CT</p>
            <Button
              className="d-block width-fit-content"
              onClick={() => setAddonPayRatesModalConsultationType(ConsultationType.Catscan)}
              size="sm"
            >
              Update
            </Button>
          </div>

          <div className="mt-2">
            <p className="mb-1 semibold text-s">Update extra image pay rates for MRI</p>
            <Button
              className="d-block width-fit-content"
              onClick={() => setAddonPayRatesModalConsultationType(ConsultationType.MRI)}
              size="sm"
            >
              Update
            </Button>
          </div>
        </div>

        {simpleStatPayRate && (
          <div>
            <SimpleStatInput
              amount={simpleStatPayRate.amount}
              page="pay rates"
              update={(amount: number) => {
                dispatch(
                  updateSpecialistPaymentAmountAction(accessToken, simpleStatPayRate.id, amount, user!.organization.enterprise.id)
                )
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
